import React from "react"

export const appStoreUrl = `https://apps.apple.com/app/id1482174597`
export const appTitle = `Dark Mode Wallpapers & Themes`
export const appSubtitle = 'HD 4k Lockscreen Backgrounds'
export const appPromo = <div style={{ textAlign: "left" }}>
• HD wallpapers for Dark Mode!<br />
• No subscriptions and no annoying pop-ups!<br />
• Hundreds of free wallpapers<br />
• (ﾉ◕ヮ◕)ﾉ*:·ﾟ<br />
• Wallpapers optimized for your iPhone, from SE to Xs Max<br />
• Categories for every taste<br />
• Special limited edition categories for seasonal holidays<br />
• 20+ featured wallpapers, updated daily<br />
• Preview wallpapers on the lock screen and home screen without leaving the app<br />
• Download wallpapers with one tap!<br />
</div>